<template>
    <div>
		<intro title="Contact us" :content="paragraph">            
		</intro>
		<div class="container">			
			<form @submit="checkForm">
        <p v-if="sent">
          {{ sent }}
        </p>
				<p v-if="errors.length">
					<b>Please correct the following error(s):</b>
					<ul>
						<li v-for="error in errors" :key="error">{{ error }}</li>
					</ul>
				</p>
					<input name="name" v-model="name" type="text" placeholder="Your name...">
					<input name="email" v-model="email"  type="email" placeholder="E-mail address...">
					<textarea  @input="messageUpdate" @keydown="messageUpdate" id="message" name="message" maxlength="1500" v-model="message" type="text" placeholder="Your Message..."> </textarea>
					<div style="color: var(--background); text-align: right;">{{messageLength}}/1500</div>
                    <button v-if="messageLength < 1501">Submit</button>
			</form>
			<div class="separator"></div>
			<div class="map">
				<a href="https://goo.gl/maps/o2mzTZGcE1QxZsqX8" target="_blank"><img :src="map" alt="broadstreet map" ></a>
				<h5>Broad Street Dental Practice, 13 Broad Street ,Whittlesey Peterborough  <br/><a href="https://goo.gl/maps/o2mzTZGcE1QxZsqX8" target="_blank"><h3>See on Google Maps  <i class="bi-map"></i></h3></a></h5>
			</div>
			
		</div>
    </div>
</template>

<style scoped>
    form{
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
    }
    form > b, form > p, h5, a {
        color: var(--background);
    }
    input, textarea{
        padding: 1rem;
        margin: 0.25rem 0;
        border-radius: .25rem;
        border-width: 0;
    }
    button{
        width: 50%;
    }
	img{
		display: none;
	}
	h5{
		padding: 0 2rem;
	}
    @media screen and (min-width: 768px){
      form, h5{
        padding: 0 5rem;
      }
      button{
        width: 30%;
      }
    }
	@media screen and (min-width: 1024px) {
		.container{
			display: flex;
			padding-bottom: 1rem;
		}
        form{
            padding: 0 var(--padding1);
			width: calc(50% - var(--padding1));
        }
		h5{
			padding: 0;
		}
		img{
			display: block;
			height: 25rem;
			width: 80%;
			padding-left: var(--padding1);
			margin: 0.25rem 0 0 0;		
		}
		.separator{
			width: 1px;
			opacity: .5;
			background-color: var(--background);
		}
		a{
			text-decoration: none;
		}
		h3{
			border-bottom: 1px solid var(--background);		
			margin: 0 auto;
			width:fit-content;
		}		
		h5{			
			padding-left: 5rem;
			text-align: center;			
		}
		textarea{
			min-height: 15rem;
		}
		button{
			margin: 1rem 0;
		}
    }
	@media screen and (min-width: 1440px) {
        form{
			width: calc(50% - var(--padding2) - var(--padding1));
            padding: 0 var(--padding1) 0 var(--padding2);
        }
		img{
			width: 100%;
		}
	}
</style>
<script>
import Intro from '../components/Intro.vue'
import map from '../assets/map.png'
export default {
  components: { Intro },
  data(){
    return{
        errors: [],
        name: null,
        email: null,
        message: null,
        sent: null,
        paragraph: ["Ask us anything! We will reply to you shortly about your request."],
        map,
        messageLength: 0
    }
  },
  methods: {
    checkForm: function (e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }

      if (!this.errors.length) {
        this.$http.post('https://email.broadstreet.dental/contact', {
          "name": this.name,
          "email": this.email,
          "content": this.message
        }).then(res => {
          if (res.status != 200) {
            console.log("Response failed pepeHands");
            this.sent = "Sorry, something went wrong! Please contact us by email on info@broadstreet.dental. This error has been logged and we will fix it as soon as we can."
          } else {
            this.name = "";
            this.email = "";
            this.message = "";
            this.sent = "Message sent successfully."
          }
          console.log(res);
        }).catch((e) => {
          console.log(e);
          this.sent = "Sorry, something went wrong! Please contact us by email on info@broadstreet.dental. This error has been logged and we will fix it as soon as we can.";
          });
        e.preventDefault();
        return true;
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    messageUpdate: function(){
      if (this.message === null){
        this.messageLength = 0;
      }
      else{
        this.messageLength = this.message.length;
      }
    }
  }  
}
</script>